// import { TranslateService } from '@ngx-translate/core';
import { Request } from 'express';
import {
  Observable,
  Subject,
} from 'rxjs';

import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Inject,
  Injectable,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import {
  makeStateKey,
  TransferState,
} from '@angular/platform-browser';
import { countryEnvironment } from '@libs/gc-common/environments/counties';
import { environment } from '@libs/gc-common/environments/environment';
import {
  UserInterface,
  UserModel,
} from '@libs/gc-common/lib/api/models/user';
import { UserDeviceUUIDModel } from '@libs/gc-common/lib/api/models/user-device-uuid';
import { UserSettingsModel } from '@libs/gc-common/lib/api/models/user-settings';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { CacheService } from '@libs/gc-common/lib/services/cache/cache.service';
import { CookieService } from '@libs/gc-common/lib/services/cookie/cookie.service';
import { ModelFactoryService } from '@libs/gc-common/lib/services/model-factory/model-factory.service';
import {
  ResourceRequestInterface,
  ResourceService,
} from '@libs/gc-common/lib/services/resource/resource.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { TranslateService } from '@ngx-translate/core';

export interface LoginRegisterCredentialsInterface {
	refreshToken?: string;
	redirectUri?: string;
	code?: string;
	accessToken?: string;
	data_access_expiration_time?: number;
	expiresIn?: number;
	graphDomain?: string;
	signedRequest?: string;
	userID?: string;
	idToken?: string;
	clientId?: string;
}

export interface LoginRegisterUserDataInterface {
	username?: string;
	email?: string;
	password?: string;
	birthday?: string;
	graphDomain?: string;
	isEmailChecked?: boolean;
	credentials?: LoginRegisterCredentialsInterface;
	rememberMe?: boolean;
	referralUser?: string;
	collabToken?: string;
	recaptcha?: string;
	eventName?: string;
	token?: string;
	uuid?: string;
	google?: {
		code?: string;
		redirectUri?: string;
	};
	accessToken?: string;
	eventBaseUrl?: string;
}

export interface UserCredentialsInterface {
	userId: string;
	apiToken: string;
	language: string;
}

@Injectable({
	providedIn: 'root'
})
export class UserService {
	
	static instance: UserService;
	
	_deviceUUID = null;
	_userReferral = environment.userReferral;
	_defaultUserReferral = this._userReferral;
	/*_userReferral = {
	 code: 'PEAU3YJ';
	 username: 'gotchosen'
	 };*/
	_userCredentialsVersion = `v2`;
	_userReferralKey = `referral-user`;
	_credentialsCookiesBaseKey = `${environment.environmentName}-credentials`;
	_credentialsCookiesKey = `mip-jwt-${this._credentialsCookiesBaseKey}-${this._userCredentialsVersion}`;
	_userLanguage = 'en';
	_loggedInUser: UserModel = null;
	_userCredential = null;
	_hasTriedCookies = false;
	_userAuthenticationObserver = new Subject();
	_userAuthenticationObservable = this._userAuthenticationObserver.asObservable();
	
	constructor(
		private http: HttpClient,
		private cacheService: CacheService,
		private cookieService: CookieService,
		private routerService: RouterService,
		private translate: TranslateService,
		private resourceService: ResourceService,
		// private translateCacheService: TranslateCacheService,
		private modelFactory: ModelFactoryService,
		private transferState: TransferState,
		@Optional() @Inject('USER_COUNTRY') userCountry: string,
		@Optional() @Inject(REQUEST) private request: Request,
		@Inject(PLATFORM_ID) private platform: any
	) {
		
		UserService.instance = this;
		
		try {
			
			this._deviceUUID = this.cacheService.getSession('device-uuid', 'GC_USER_DEVICE_UUID');
			// console.log('user.service->constructor(): this._deviceUUID', this._deviceUUID);
			
			if (!userCountry) {
				const USER_COUNTRY = makeStateKey<string>('USER_COUNTRY');
				userCountry = transferState.get(USER_COUNTRY, environment.defaultCountry);
			}
			
			// console.log('user.service->constructor(): userCountry', userCountry);
			
			if (userCountry && userCountry in countryEnvironment) {
				// console.log('user.service->constructor(): countryEnvironment[userCountry]', countryEnvironment[userCountry]);
				
				const countryEnv = countryEnvironment[userCountry];
				
				if (countryEnv && countryEnv.userReferral) {
					this._userReferral = countryEnv.userReferral;
					this._defaultUserReferral = countryEnv.userReferral;
				}
				
			}
			
			// console.log('user.service->constructor(): this._userReferral', this._userReferral);
			// console.log('user.service->constructor(): this._defaultUserReferral', this._defaultUserReferral);
			
			if (utilsFactory.isBrowser) {
				
				// console.log('user.service->constructor(): window.gcApp_credentials', typeof window['gcApp_credentials'], window['gcApp_credentials']);
				
				if (window['gcApp_credentials']) {
					this.setAuthentication(window['gcApp_credentials']);
				}
				
				const cookies = cookieService.getCookies();
				// console.log('user.service->constructor(): cookies', cookies);
				
				// tslint:disable-next-line:forin
				for (const i in cookies) {
					if (i.indexOf(this._credentialsCookiesBaseKey) > -1 && i !== this._credentialsCookiesKey) {
						// console.log('user.service->constructor(): i', i, cookies[i]);
						cookieService.removeCookie(i);
					}
				}
				
				const userReferral = this.cacheService.getCache(this._userReferralKey, 'data');
				// console.log('user.service->constructor(): userReferral', userReferral);
				
				if (userReferral) {
					this._userReferral = userReferral;
					// console.log('user.service->constructor(): this._userReferral', this._userReferral);
				}
				
			}
			
			let token = null;
			
			if (request) {
				if (request.query['token']) {
					token = request.query['token'] + ''; // forcing it to be a string
					// console.log('user.service->constructor(): REQUEST token', token);
				}
			}
			else if (utilsFactory.isBrowser) {
				const params = utilsFactory.deserializeParams(window.location.search);
				// console.log('user.service->constructor(): LOCATION params', params);
				
				if (params.token) {
					token = params.token;
					// console.log('user.service->constructor(): LOCATION token', token);
				}
			}
			
			// console.log('user.service->constructor(): token', token, typeof token);
			
			if (token) {
				
				token = decodeURIComponent(token);
				// console.log('user.service->constructor(): token decodeURIComponent()', token);
				
				token = utilsFactory.atob(token);
				// console.log('user.service->constructor(): token atob()', token);
				
				const credentials = JSON.parse(token);
				// console.log('user.service->constructor(): credentials', credentials);
				
				if (credentials && credentials.apiToken) {
					this._userCredential = credentials;
					// console.log('user.service->constructor(): this._userCredential', this._userCredential);
				}
			}
			
			// console.log('user.service->constructor(): this._userCredential', this._userCredential);
			
			if (!this._userCredential) {
				try {
					
					this._userCredential = this.getCredentials();
					// console.log('user.service->constructor(): this.getCredentials()', this._userCredential);
					
					// this.init(this._userCredential);
					
				}
				catch (e) {
					console.warn('user.service->constructor(): getCredentials(): ERROR', e);
					this.init();
					throw e;
				}
			}
			
			if (this._userCredential) {
				this._userLanguage = this._userCredential.language;
				// console.log('user.service->constructor() this._userLanguage', this._userLanguage);
			}
			
			this.init(this._userCredential);
		}
		catch (e) {
			
			console.warn('user.service->constructor(): ERROR', e);
			
			// since this is coming from the SSR, is mostly likely that we are able to
			// get the user lang, once we get the cookies on the SSR as well
			this._userLanguage = isPlatformBrowser(this.platform)
				? /*this.translateCacheService.getCachedLanguage() || */this.translate.getBrowserLang() || 'en'
				: this.getLangFromServerSideCookie() || 'en';
			
			// console.log('user.service->constructor() this._userLanguage', this._userLanguage);
		}
		
		if (isPlatformBrowser(this.platform)) {
			// this.translateCacheService.init();
		}
		
	}
	
	getCredentials() {
		try {

			/*if (this._hasTriedCookies) {
				return null;
			}*/

			const credentials = this._userCredential || this.cookieService.getCookie(this._credentialsCookiesKey);
			// console.log('user.service->getCredentials() credentials', this._credentialsCookiesKey, credentials);

			// this._hasTriedCookies = true;
			
			if (!credentials) {
				throw new Error(`There is no user 'credentials' object.`);
			}
			
			if (credentials.apiToken) {
				this.resourceService.setAuthorizationToken(['api', 'scraperApi', 'mipWebApi', 'collabApi'], credentials.apiToken, true);
			}
			else {
				throw new Error(`There is no user 'apiToken' on 'credentials' object.`);
			}
			
			this._userCredential = credentials;
			// console.log('user.service->getCredentials() this._userCredential', this._userCredential);
			
			return credentials;
		}
		catch (e) {
			// console.error('user.service->getCredentials() ERROR', e);
			throw e;
		}
	}
	
	getLangFromServerSideCookie() {
		try {
			// console.log('user.service->getLangFromServerSideCookie() this.request.cookies', this.request.cookies);
			
			if (this.request.cookies) {
				return this.request.cookies.lang;
			}
			else {
				return null;
			}
			
		}
		catch (e) {
			// console.log('user.service->getLangFromServerSideCookie() ERROR', e);
			return null;
		}
	}
	
	isAuthenticated() {
		
		try {
			// console.log('user.service->isAuthenticated(): this._userCredential', this._userCredential);
			return !!this.getCredentials();
		}
		catch (e) {
			return false;
		}
		
	}
	
	isAuthorized() {
		return true;
	}
	
	getReferralUser(): { code: string, username: string } {
		return this._userReferral;
	}
	
	removeReferralUser() {
		this.cacheService.removeCache(this._userReferralKey, 'data');
		this._userReferral = this._defaultUserReferral;
	}
	
	getGraphDomainPayload(networkName: string, payload: LoginRegisterUserDataInterface = {}) {
		try {
			
			// console.log('user.service->getGraphDomainPayload(): networkName, payload', networkName, payload);
			
			let postData = null;
			
			if (!payload.graphDomain) {
				payload.graphDomain = 'gotchosen';
			}
			
			switch (payload.graphDomain) {
				
				// FACEBOOK login
				case 'facebook':
					if (
						payload.credentials.userID &&
						payload.credentials.accessToken
					) {
						postData = {
							graphDomain: payload.graphDomain,
							accessToken: payload.credentials.accessToken,
							userID: payload.credentials.userID,
							email: payload.email,
							recaptcha: payload.recaptcha
						};
					}
					else {
						throw new Error(`'credentials' is not valid`);
					}
					break;
				
				// GOTCHOSEN login
				case 'gotchosen':
					if (payload.password) {
						postData = {
							graphDomain: payload.graphDomain,
							email: payload.email,
							password: payload.password,
							birthday: payload.birthday,
							username: payload.username,
							recaptcha: payload.recaptcha,
							eventName: payload.eventName
						};
					}
					else {
						throw new Error(`The 'payload' data provided for user registration is not valid`);
					}
					break;
				
				case 'google':
					
					if (payload.credentials.code && !payload.credentials.redirectUri) {
						throw new Error(`The 'redirectUri' must be provided along with the 'code'`);
					}
					
					if (payload.credentials && (payload.credentials.accessToken || (payload.credentials.code && payload.credentials.redirectUri))) {
						postData = {
							graphDomain: payload.graphDomain,
							uuid: payload.uuid,
							accessToken: {
								accessToken: payload.credentials.accessToken,
								code: payload.credentials.code,
								redirectUri: payload.credentials.redirectUri
							}
						};
					}
					else {
						throw new Error(`The 'payload' provided is not valid, must contain 'credentials' and 'accessToken' within it`);
					}
					break;
				
				case 'apple':
					if (payload.credentials && payload.credentials.code) {
						postData = {
							graphDomain: payload.graphDomain,
							uuid: payload.uuid,
							accessToken: {
								code: payload.credentials.code,
								redirectUri: payload.credentials.redirectUri,
								clientId: payload.credentials.clientId
							}
						};
					}
					else {
						throw new Error(`The 'payload' data provided for user registration is not valid`);
					}
					break;
			}
			
			postData.referralUser = payload.referralUser || this._userReferral.code;
			
			if (payload.collabToken) {
				postData.collabToken = payload.collabToken;
			}
			
			// console.log('user.service->getGraphDomainPayload(): postData', postData);
			
			return postData;
			
		}
		catch (e) {
			throw e;
		}
	}
	
	getSsrUser(userId): UserModel {
		try {
			const response = this.resourceService.getResourceFromSsrData('api', {
				resource: 'users',
				path: `/user/${userId}`
			});
			
			return this.modelFactory.userFactory.build(response);
		}
		catch (e) {
			throw e;
		}
	}
	
	onUserAuthentication(): Observable<any> {
		// console.log('user.service->onUserAuthentication(): this._loggedInUser', this._loggedInUser);
		
		/*if (this._loggedInUser) {
		 setTimeout(() => {
		 this._userAuthenticationObserver.next(this._loggedInUser);
		 }, 1);
		 }
		 
		 return this._userAuthenticationObservable;*/
		
		if (this._loggedInUser) {
			return new Observable<any>(observer => {
				observer.next(this._loggedInUser);
			});
		}
		else {
			return this._userAuthenticationObservable;
		}
		
	}
	
	setDeviceUUID(deviceUUID) {
		this._deviceUUID = deviceUUID;
		this.cacheService.setSession('device-uuid', 'GC_USER_DEVICE_UUID', this._deviceUUID);
		console.log(`user.service->setDeviceUUID(): this._deviceUUID`, this._deviceUUID);
	}
	
	getDeviceUUID() {
		return this._deviceUUID;
	}
	
	async init(credentials?) {
		
		try {
			if (credentials) {
				// this.cookieService.setCookie(this._credentialsCookiesKey, credentials, 365);
				// console.log('user.service->init(): IF', this._userCredential);
				this._userCredential = this.getCredentials() || credentials;
			}
			else {
				// console.log('user.service->init(): ELSE', this._userCredential);
				this._userCredential = null;
			}
			
			// console.log('user.service->init(): this._userCredential', this._userCredential);
			
			let cacheAuthenticatedUserData = null;
			
			if (this._userCredential) {
				// console.log('user.service->init(): this._userCredential', this._userCredential);
				
				this.resourceService.setAuthorizationToken(['api', 'scraperApi', 'mipWebApi', 'collabApi'], this._userCredential.apiToken, true);
				
				if (utilsFactory.isBrowser) {
					cacheAuthenticatedUserData = this.cacheService.getCache('users', 'authenticated-user-data');
				}
				
				// console.log('user.service->init() cacheAuthenticatedUserData', cacheAuthenticatedUserData);
				
				if (!this._loggedInUser) {
					if (cacheAuthenticatedUserData) {
						cacheAuthenticatedUserData.isAuthenticated = true;
						this._loggedInUser = this.modelFactory.userFactory.build(cacheAuthenticatedUserData);
					}
					else {
						// console.log('user.service->init() getLoginUser()');
						this._loggedInUser = await this.getLoginUser(false);
					}
				}
				
				const { application_language } = this._loggedInUser.getLanguages();
				// console.log('user.service->init() getLoginUser()', application_language);
				
				const language = application_language || 'en';
				// console.log('user.service->init() language', language, this._userCredential.language);
				
				// console.log('user.service->init() getCookie', this.cookieService.getCookie(this._credentialsCookiesKey));
				
				if (
					language !== this._userCredential.language ||
					!this.cookieService.getCookie(this._credentialsCookiesKey)
				) {
					this._userCredential.language = language;
					this.cookieService.setCookie(this._credentialsCookiesKey, this._userCredential, 365);
				}
				
				// console.log('user.service->init() this._loggedInUser', this._loggedInUser);
				this._userAuthenticationObserver.next(this._loggedInUser);
				
			}
			
			const params = await this.routerService.getRouteParam();
			// console.log('user.service->init(): getRouteParam(): params', params);
			
			if (params.code && params.userId && (!this._loggedInUser || (this._loggedInUser && params.code !== this._loggedInUser.referralCode))) {
				this._userReferral = {
					code: params.code,
					username: params.userId.replace('@', '')
				};
				// console.log('user.service->init(): getRouteQueryParam(): this._userReferral', this._userReferral);
				
				this.cacheService.setCache(this._userReferralKey, 'data', this._userReferral, { hours: 24 });
			}
			
		}
		catch (e) {
			throw e;
		}
	}
	
	async getLoginUser(fromCache?: boolean): Promise<UserModel> {
		// console.log('user.service->getLoginUser()');
		
		try {
			
			let _loggedInUser = this._loggedInUser;
			
			if (this.isAuthenticated()) {
				
				let cacheAuthenticatedUserData = null;
				
				if (utilsFactory.isBrowser) {
					cacheAuthenticatedUserData = this.cacheService.getCache('users', 'authenticated-user-data');
				}
				// console.log('user.service->getLoginUser(): FROM SERVER ', cacheAuthenticatedUserData);
				
				if (cacheAuthenticatedUserData) {
					cacheAuthenticatedUserData.isAuthenticated = true;
					_loggedInUser = this.modelFactory.userFactory.build(cacheAuthenticatedUserData);
				}
				// console.log('user.service->getLoginUser(): FROM SERVER ', _loggedInUser);
				
				if (_loggedInUser && fromCache !== false) {
					// console.log('user.service->getLoginUser(): FROM MEMORY', _loggedInUser);
					_loggedInUser.isAuthenticated = true;
					// console.log('user.service->getLoginUser(): FROM MEMORY', this._loggedInUser);
				}
				else {
					
					const params: ResourceRequestInterface = {
						resource: 'users',
						path: `/user/login`,
						preventDuplicates: true,
						fromCache
					};
					
					const response = await this.resourceService.post('api', params);
					// console.log('user.service->getLoginUser(): response', response);
					
					response.isAuthenticated = true;
					
					_loggedInUser = this.modelFactory.userFactory.build(response);
					
				}
				
				const credentials = this.getCredentials();
				// console.log('user.service->getLoginUser(): credentials', credentials);
				
				const language = _loggedInUser.getSettings('application_language');
				// console.log('user.service->getLoginUser(): language', language);
				
				if (language && language !== credentials.language) {
					credentials.language = language;
					// console.log('[AKI] user.service->getLoginUser(): credentials', credentials);
					
					this.cookieService.setCookie(this._credentialsCookiesKey, credentials, 365);
					this.translate.use(language);
				}
				
			}
			
			/*const usernameApiUrl = this.resourceService.getApiUrl('api', {
			 path: `/user/${_loggedInUser.username}`
			 });
			 
			 this.cacheService.setCache('users', usernameApiUrl, _loggedInUser);
			 
			 // console.log('user.service->getLoginUser(): usernameApiUrl', usernameApiUrl);
			 */
			return _loggedInUser;
			
		}
		catch (e) {
			this.logout(false);
			throw e;
		}
	}
	
	async getUser(userId: string | number, fromCache?: boolean, username?: string): Promise<UserModel> {
		// console.log('user.service->getUser(): userId', userId, fromCache);
		
		try {
			
			if (typeof userId === 'string' && userId.indexOf('@') > -1) {
				userId = userId.split('@')[1];
			}
			
			if (!userId || userId === 'null') {
				throw new Error(`The user ID or username must be provided`);
			}
			
			const params: ResourceRequestInterface = {
				resource: 'users',
				path: `/user/${isNaN(Number(userId)) ? '' : '/gc/'}${userId}`,
				fromCache
			};
			
			const response = await this.resourceService.get('api', params);
			// console.log('user.service->getUser(): response', userId, response);
			
			return this.modelFactory.userFactory.build(response);
			
		}
		catch (e) {
			console.error('user.service->getUser(): ERROR', e);
			throw e;
		}
		
	}
	
	async setAuthentication(userData: UserInterface) {
		try {
			
			userData.isAuthenticated = true;
			this._loggedInUser = this.modelFactory.userFactory.build(userData);
			
			// enforcing isAuthenticated = true
			this._loggedInUser.fill({ isAuthenticated: true });
			
			// console.log('user.service->setAuthentication(): _loggedInUser', this._loggedInUser);
			
			if (this._loggedInUser.apiToken) {
				// console.log('user.service->setAuthentication(): this._loggedInUser.apiToken', this._loggedInUser.apiToken);
				this.resourceService.setAuthorizationToken(['api', 'scraperApi', 'mipWebApi', 'collabApi'], this._loggedInUser.apiToken, true);
			}
			else {
				throw new Error(`'apiToken' is ${this._loggedInUser.apiToken}`);
			}
			
			// console.log('user.service->setAuthentication(): setCache', this._loggedInUser);
			this.cacheService.setCache('users', 'authenticated-user-data', this._loggedInUser, { days: 365 });
			
			console.log('user.servinpce->setAuthentication(): isAuthenticated', this._loggedInUser.isAuthenticated);
			// console.log('user.service->setAuthentication(): application_language', this._loggedInUser.getSettings('application_language'));
			// console.log('user.service->setAuthentication(): content_language', this._loggedInUser.getSettings('content_language'));
			
			this._userCredential = {
				apiToken: this._loggedInUser.apiToken,
				userId: this._loggedInUser.id,
				language: this._loggedInUser.getSettings('application_language') || this._userLanguage
			};
			// console.log('user.service->setAuthentication(): this._userCredential', this._userCredential);
			
			// console.log('user.service->setAuthentication(): this._userCredential.language', this._userCredential.language);
			
			this.translate.use(this._userCredential.language);
			this.cookieService.setCookie(this._credentialsCookiesKey, this._userCredential, 365);
			
			// console.log('user.service->setAuthentication(): this._loggedInUser', this._loggedInUser);
			this._userAuthenticationObserver.next(this._loggedInUser);
			
			if (utilsFactory.isBrowser && window['gcApp_onUserAuthentication']) {
				window['gcApp_onUserAuthentication'].postMessage(JSON.stringify(this._loggedInUser));
			}
			
			return this._loggedInUser;
			
		}
		catch (e) {
			throw e;
		}
	}
	
	async registerUser(payload: LoginRegisterUserDataInterface): Promise<UserModel> {
		// console.log('user.service->registerUser(): payload', payload);
		try {
			
			const postData = this.getGraphDomainPayload(payload.graphDomain, payload);
			// console.log('user.service->registerUser(): postData', postData);
			
			const response = await this.resourceService.post('api', {
				path: `/user`,
				data: { ...postData, uuid: this._deviceUUID },
				token: false
				// removeHeaders: ['device-uuid']
			});
			
			return this.modelFactory.userFactory.build(response);
			
		}
		catch (e) {
			throw e;
		}
	}
	
	async login(payload: LoginRegisterUserDataInterface): Promise<UserModel> {
		// console.log('user.service->login(): payload', payload);
		try {
			
			let response = null;
			
			this._hasTriedCookies = false;
			
			if (payload.token) {
				
				response = await this.resourceService.post('api', {
					path: `/user/login`,
					preventDuplicates: true,
					data: {
						uuid: this._deviceUUID
					},
					headers: {
						Authorization: `Bearer ${payload.token}`
					}
				});
				
			}
			else {
				
				const postData = this.getGraphDomainPayload(payload.graphDomain, payload);
				// console.log('user.service->login(): postData', postData);
				
				if (postData) {
					
					response = await this.resourceService.post('api', {
						path: `/user/login`,
						preventDuplicates: true,
						data: {
							...postData,
							uuid: this._deviceUUID
						}
					});
					
				}
				else {
					throw new Error(`'postData' is ${postData}`);
				}
				
			}
			
			console.log('user.service-»login(): response', response);
			
			const loggedInUser = this.modelFactory.userFactory.build(response);
			// console.log('user.service->login(): loggedInUser.settings', loggedInUser.settings);
			
			return loggedInUser;
			
		}
		catch (e) {
			console.error('user.service->login(): ERROR', e);
			this.logout(false);
			throw e;
		}
	}
	
	async logout(refresh = true, resetReferralUser?) {
		
		// console.log('user.service->logout(): this._loggedInUser', this._loggedInUser);
		
		try {

			this._hasTriedCookies = false;
			
			if (this._loggedInUser) {
				this._loggedInUser.isAuthenticated = false;
			}
			
			this._loggedInUser = null;
			this._userCredential = null;
			
			if (resetReferralUser !== false) {
				this.removeReferralUser();
			}
			
			// console.log('user.service->logout(): this._credentialsCookiesKey', this._credentialsCookiesKey);
			
			this.cacheService.clearCache('all');
			
			this.cookieService.removeCookie(this._credentialsCookiesKey);
			this.cookieService.removeCookie('lang');
			this.resourceService.removeAuthorizationToken('all');
			
			if (refresh) {
				this.routerService.navigateTo('.', {
					queryParams: {
						token: null
					},
					queryParamsHandling: 'merge'
				});
				setTimeout(() => {
					window.location.reload();
				}, 100);
			}
		}
		catch (e) {
			throw e;
		}
	}
	
	async forgotPassword(email: string) {
		// console.log('user.service->forgotPassword(): email', email);
		try {
			
			if (!utilsFactory.isEmailValid(email)) {
				throw new Error('The email provided is not valid');
			}
			
			const response = await this.resourceService.post('api', {
				path: `/user/forgot-password`,
				data: { email }
			});
			
			// console.log('user.service->forgotPassword(): response', response);
			
			return response;
			
		}
		catch (e) {
			throw e;
		}
	}
	
	async changePassword(payload: { email, code, newPassword }) {
		// console.log('user.service->changePassword(): payload', payload);
		try {
			
			if (!payload) {
				throw new Error(`'payload' must be provided.`);
			}
			
			if (!utilsFactory.isEmailValid(payload.email)) {
				throw new Error('The email provided is not valid');
			}
			
			const response = await this.resourceService.put('api', {
				path: `/user/forgot-password`,
				data: {
					email: payload.email,
					recoveryToken: payload.code.toString(),
					newPassword: payload.newPassword
				}
			});
			
			// console.log('user.service->changePassword(): response', response);
			
			return this.modelFactory.userFactory.build(response);
			
		}
		catch (e) {
			throw e;
		}
	}
	
	async validateCode(payload: { code, email }): Promise<UserModel> {
		try {
			
			// check email and if is valid
			if (!payload.email) {
				throw new Error(`'email' must be provided.`);
			}
			else if (!utilsFactory.isEmailValid(payload.email)) {
				throw new Error(`'email' provided is invalid.`);
			}
			
			// is there a code value
			if (!payload.code) {
				throw new Error(`'code' must be provided.`);
			}
			
			// is the code value length greater than 6 and smaller then 6
			if (
				payload.code.toString().length < 6 ||
				payload.code.toString().length > 6
			) {
				throw new Error(`'code' provided is invalid.`);
			}
			
			const response = await this.resourceService.post('api', {
				path: `/user/validate-email`,
				data: {
					token: payload.code,
					email: payload.email
				}
			});
			
			// console.log('user.service->validateCode(): response', response);
			
			return this.modelFactory.userFactory.build(response);
			
		}
		catch (e) {
			throw e;
		}
	}
	
	async getUsersByDevice(deviceUUID: string, fromCache = true): Promise<UserDeviceUUIDModel[]> {
		try {
			
			// console.log('user.service->getUsersByDevice(): deviceUUID', deviceUUID);
			
			if (!deviceUUID) {
				throw new Error(`The deviceUUID must be provided`);
			}
			
			const params: ResourceRequestInterface = {
				resource: 'device-users',
				path: `/user/devices/uuid/${deviceUUID}`,
				fromCache
			};
			
			const response = await this.resourceService.get('api', params);
			// console.log('user.service->getUsersByDevice(): response', response);
			
			const users = this.modelFactory.userDeviceUUIDFactory.buildCollection(response.users);
			// console.log('user.service->getUsersByDevice(): users', users);
			
			// the response don't return UserModel patter. DO NOT BUILD THE RESPONSE WITH "userFactory.buildCollection()"
			return users;
			
		}
		catch (e) {
			throw e;
		}
	}
	
	async getUserByDeviceAndUserId(deviceUUID: string, userId: string, fromCache = true): Promise<UserDeviceUUIDModel> {
		try {
			
			// console.log('user.service->getUserByDeviceAndUserId(): deviceUUID', deviceUUID);
			
			if (!deviceUUID) {
				throw new Error(`The 'deviceUUID' must be provided`);
			}
			
			if (!userId) {
				throw new Error(`The 'userId' must be provided`);
			}
			
			const users = await this.getUsersByDevice(deviceUUID);
			// console.log('user.service->getUserByDeviceAndUserId(): users', users);
			
			const user = users.filter(item => item.id === parseInt(userId, 10))[0];
			// console.log('user.service->getUserByDeviceAndUserId(): user', user);
			
			// the response don't return UserModel patter. DO NOT BUILD THE RESPONSE WITH "userFactory.buildCollection()"
			return user;
			
		}
		catch (e) {
			throw e;
		}
	}
	
	async deleteUser(credentials: { username, password, recaptcha, deviceUuid }): Promise<UserDeviceUUIDModel> {
		try {
			
			// console.log('user.service->deleteUser(): credentials', credentials);
			
			if (!credentials) {
				throw new Error(`The 'credentials' must be provided`);
			}
			
			if (!credentials.username) {
				throw new Error(`username.required`);
			}
			
			if (!credentials.password) {
				throw new Error(`password.required`);
			}
			
			if (!credentials.recaptcha) {
				throw new Error(`recaptcha.required`);
			}
			
			const response = await this.resourceService.post('api', {
				resource: 'device-users',
				path: `/user/process-delete`,
				data: credentials,
				removeHeaders: ['device-uuid']
			});
			// console.log('user.service->deleteUser(): response', response);
			
			const users = this.modelFactory.userDeviceUUIDFactory.buildCollection(response.users);
			// console.log('user.service->deleteUser(): users', users);
			
			const user = users.filter(item => item.username === credentials.username)[0];
			// console.log('user.service->deleteUser(): user', user);
			
			if (!user) {
				throw new Error(`The user ${credentials.username} has not found!`);
			}
			
			// the response don't return UserModel patter. DO NOT BUILD THE RESPONSE WITH "userFactory.buildCollection()"
			return user;
			
		}
		catch (e) {
			throw e;
		}
	}
	
	async deleteUserDevice(deviceUUID: string, credentials: { username, password, recaptcha }): Promise<UserDeviceUUIDModel> {
		try {
			
			throw {
				'message': 'ReCaptcha failed: bad-score',
				'error': 'recaptcha-failed'
			};
			
			// console.log('user.service->deleteUserDevice(): credentials', credentials);
			
			if (!credentials) {
				throw new Error(`The 'credentials' must be provided`);
			}
			
			if (!credentials.username) {
				throw new Error(`username.required`);
			}
			
			if (!credentials.password) {
				throw new Error(`password.required`);
			}
			
			if (!credentials.recaptcha) {
				throw new Error(`recaptcha.required`);
			}
			
			const response = await this.resourceService.post('api', {
				resource: 'device-users',
				path: `/user/devices/process-delete/uuid/${deviceUUID}`,
				data: credentials,
				removeHeaders: ['device-uuid']
			});
			// console.log('user.service->deleteUserDevice(): response', response);
			
			const users = this.modelFactory.userDeviceUUIDFactory.buildCollection(response.users);
			// console.log('user.service->deleteUserDevice(): users', users);
			
			const user = users.filter(item => item.username === credentials.username)[0];
			// console.log('user.service->deleteUserDevice(): user', user);
			
			if (!user) {
				throw new Error(`The user ${credentials.username} has not found!`);
			}
			
			// the response don't return UserModel patter. DO NOT BUILD THE RESPONSE WITH "userFactory.buildCollection()"
			return user;
			
		}
		catch (e) {
			throw e;
		}
	}
	
	async saveSetting(setting: UserSettingsModel) {
		try {
			
			// console.log('user.service->saveSetting(): setting', setting);
			
			if (!this.isAuthenticated()) {
				throw new Error(`User MUST be logged in!!`);
			}
			
			if (!setting) {
				throw new Error(`'setting' must be provided`);
			}
			
			const { id, key, value } = setting;
			let response = null;
			
			if (id) {
				response = await this.resourceService.put('api', {
					resource: 'users',
					path: `/user/settings/${id}`,
					data: {
						id,
						key,
						value,
						visibility: 'private'
					},
					removeHeaders: ['device-uuid']
				});
			}
			else {
				response = await this.resourceService.post('api', {
					resource: 'users',
					path: `/user/settings`,
					data: {
						id,
						key,
						value,
						visibility: 'private'
					},
					removeHeaders: ['device-uuid']
				});
			}
			
			// console.log('user.service->saveSetting(): response', response);
			
			await this._loggedInUser.fill(response);
			
			const collabDashboardThemeSetting = this._loggedInUser.getSettingByKey('collab_dashboard_theme', response.settings);
			// console.log('user.service->saveSetting(): collabDashboardThemeSetting', collabDashboardThemeSetting);
			
			return await setting.fill(collabDashboardThemeSetting);
			
		}
		catch (e) {
			throw e;
		}
	}
	
}
